exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-buy-instagram-followers-tsx": () => import("./../../../src/pages/buy-instagram-followers.tsx" /* webpackChunkName: "component---src-pages-buy-instagram-followers-tsx" */),
  "component---src-pages-buy-instagram-likes-tsx": () => import("./../../../src/pages/buy-instagram-likes.tsx" /* webpackChunkName: "component---src-pages-buy-instagram-likes-tsx" */),
  "component---src-pages-buy-instagram-views-tsx": () => import("./../../../src/pages/buy-instagram-views.tsx" /* webpackChunkName: "component---src-pages-buy-instagram-views-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-free-instagram-followers-tsx": () => import("./../../../src/pages/free-instagram-followers.tsx" /* webpackChunkName: "component---src-pages-free-instagram-followers-tsx" */),
  "component---src-pages-free-instagram-likes-tsx": () => import("./../../../src/pages/free-instagram-likes.tsx" /* webpackChunkName: "component---src-pages-free-instagram-likes-tsx" */),
  "component---src-pages-free-instagram-views-tsx": () => import("./../../../src/pages/free-instagram-views.tsx" /* webpackChunkName: "component---src-pages-free-instagram-views-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-track-tsx": () => import("./../../../src/pages/order-track.tsx" /* webpackChunkName: "component---src-pages-order-track-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */),
  "component---src-pages-steps-tsx": () => import("./../../../src/pages/steps.tsx" /* webpackChunkName: "component---src-pages-steps-tsx" */),
  "component---src-pages-terms-of-services-tsx": () => import("./../../../src/pages/terms-of-services.tsx" /* webpackChunkName: "component---src-pages-terms-of-services-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */)
}

